import { PieChart, SpaceBetween } from "@cloudscape-design/components";
import WhiteCard from "../../customs/components/WhiteCard";


export default function Fintechs({ fintechs, info21yield }) {

    let totalLoaned = info21yield.totalLoaned;

    let data = [];
    fintechs.map((fintech) => {
        const percentage = Math.round(fintech.loanAmount / totalLoaned * 100);
        data.push({ title: fintech.name, value: percentage });
    });

    return(
    <WhiteCard>
        <SpaceBetween size="l">
            <div className="title-main">Composition du portefeuille de crédit privé</div>
            <div className="description-medium">Notre sélectionnons est exclusive : une très bonne qualité de crédit et une forte solidité financière.</div>
            <div className="description-medium">Nous mission est d'accompagner le développement économique, par le financement à des entreprises en croissance.</div>
            <div className="description-medium">Nous surveillons que nos emprunteurs génèrent du profit sur les opérations financées. En conséquence, ils remboursent facilement et resouscrivent les mois suivants. C'est un modèle pérenne et rentable pour tous.</div>
        
            <PieChart
                data={data}
                fitHeight
                hideFilter
                innerMetricDescription="fintechs"
                innerMetricValue={fintechs.length}
                size="large"
                variant="donut"
            />

        </SpaceBetween>
    </WhiteCard>    
    )
}