import { useState } from "react";

import Fintechs from "./fintechs";
import Perf21Yield from "./perf21yield";
import CustomButton from "../../customs/components/CustomButton";
import CustomListButton from "../../customs/components/CustomListButton";


export default function LayoutComposition({ fintechs, info21yield }) {

    const pages = [
        {
            key: "0",
            label: "Source du Rendement",
            component: <Perf21Yield info21yield={info21yield} />
        },
        {
            key: "1",
            label: "Fintechs",
            component: <Fintechs fintechs={fintechs} info21yield={info21yield} />
        }
    ]

    let [selectedPage, setSelectedPage] = useState(pages["1"]);
    let [selectedKey, setSelectedKey] = useState("1");

    function handleChangePage(key) {
        setSelectedPage(pages[key]);
        setSelectedKey(key);
    }

    return(
        <>
            <CustomListButton
                handleChangePage={handleChangePage}
                selectedKey={selectedKey}
                pages={pages}
            />

            {selectedPage.component}
            
        </>
    )
}