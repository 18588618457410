import { useEffect, useState } from "react";
import { getUTCDateFromISOStringDate, getUTCDateNow } from "../../utils/dateUtils";
import { calculateCompoundedValue } from "../../utils/calculatePayout";
import { toFormatCurrency, toFormatDate } from "../../utils/toFormat";
import WhiteCard from "../../customs/components/WhiteCard";
import { SpaceBetween, Table, Grid } from "@cloudscape-design/components";
import CustomSelect from "../../customs/CustomSelect";
import CustomDateInput from "../../customs/CustomDateInput";
import { Button } from "antd";
import CustomInputNumber from "../../customs/CustomInputNumber";
import { DeleteOutlined } from "@ant-design/icons";
import Forecasting from "./forecasting";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import { inputCurrencyFormatter, inputCurrencyParser } from "../../utils/formatters";


export default function CapitalConstitution(){

    const INVESTMENT = "Ajout";
    const WITHDRAWAL = "Retrait";

    const selectOptions = [
        {value: "1", label: INVESTMENT},
        {value: "2", label: WITHDRAWAL}
    ]
    const initialBalance = 0;
    const tdy = getUTCDateNow();

    let [annualYield, setAnnualYield] = useState(10);

    let [method, setMethod] = useState({value: "1", label: INVESTMENT});
    let [amountNew, setAmountNew] = useState(0);
    let [dateNew, setDateNew] = useState();
    let [transactions, setTransactions] = useState([]);
    let [lastId, setLastId] = useState(0);
    let [maxWithdrawal, setMaxWithdrawal] = useState(initialBalance);
    let [data, setData] = useState({});

    let [hasError, setHasError] = useState(false);
    let [errorLabel, setErrorLabel] = useState("");

    const columnsDefinition = [
        {
            id: 'date',
            header: 'Date',
            cell: item => toFormatDate(item.date),
            isRowHeader: true,
        },
        {
            id: 'value',
            header: 'Montant',
            cell: item => toFormatCurrency(item.amount),
        },
        {
            id: 'type',
            header: 'Catégorie',
            cell: item => item.type.label,
        },
        {
            id: 'delete',
            header: 'Suppression',
            cell: item => <div className="icon-container"><DeleteOutlined onClick={() => handleDeleteTransaction(item)} /></div>,
        },
    ]

    function recalculateChartData() {

        let balance = initialBalance;
        let fromDate = tdy;
        // duration = 10 years
        let lastDate = getUTCDateNow();
        lastDate.setFullYear(lastDate.getFullYear() + 10);

        const balances = [
            {
                date: fromDate,
                value: balance
            }
        ];
        for (let t of transactions) {
            const {date, type, amount} = t;
            // Add compounded interest accumulated
            var toDate = date;
            balance = calculateCompoundedValue(fromDate, toDate, annualYield/100, balance);

            // Add or Remove the transaction amount
            if(type.label === INVESTMENT){ 
                balance += amount;
            }
            else{
                if(balance - amount < 0){
                    setData(balances);
                    return;
                }
                balance -= amount; 
            }
            // Record the new balance
            balances.push({
                date: toDate,
                value: balance
            }); 
            // Set fromDate to previous transaction date
            fromDate = toDate;
        }

        var nbYearRemaining = Math.floor((lastDate - fromDate) / (1000*60*60*24*365));        
        for( let i=0; i< nbYearRemaining; i++) {

            var toDate = new Date(Date.UTC(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate()));
            balance = calculateCompoundedValue(fromDate, toDate, annualYield/100, balance);

            balances.push({
                date: toDate,
                value: balance
            }); 

            fromDate = toDate;
        }

        balance = calculateCompoundedValue(fromDate, lastDate, annualYield/100, balance);

        balances.push({
            date: lastDate,
            value: balance
        }); 

        setData(balances);
    }
    // recalculate data for charts upon transactions change
    useEffect(() => { recalculateChartData(); }, [transactions])

    const updateMaxWithdrawal = (d) => {

        var toDate = getUTCDateFromISOStringDate(d);
        var prevEntry = transactions.filter(t => t.date < toDate);
        
        if(prevEntry.length === 0) {
            var fromDate = tdy;
            var prevAmount = initialBalance;
            var newMaxWithdrawal = calculateCompoundedValue(fromDate, toDate, annualYield/100, prevAmount)
            setMaxWithdrawal(newMaxWithdrawal);
        }
        else{
            prevEntry = prevEntry[prevEntry.length - 1];
            var fromDate = prevEntry.date;
            var prevAmount = prevEntry.amount;
            var newMaxWithdrawal = calculateCompoundedValue(fromDate, toDate, annualYield/100, prevAmount);
            setMaxWithdrawal(newMaxWithdrawal);
        }
    }

    const handleChangeDate = (d) => {

        setDateNew(d);
        
        if(method.label === WITHDRAWAL){
            // select all previous transactions from data which are before the new date d
            updateMaxWithdrawal(d)
        }
    }

    const handleSetMax = () => {
        consoleOnlyLocally(maxWithdrawal);
        setAmountNew(maxWithdrawal);
    }

    const handleChangeMethod = (value) => {
        setMethod(value);

        if(value.label === WITHDRAWAL){
            updateMaxWithdrawal(dateNew);
        }
    }

    const addTransactionToArray = (newTransaction) => {
        let updatedTransactions = [...transactions, newTransaction];
        updatedTransactions.sort((a, b) => a.date - b.date);
        setTransactions(updatedTransactions);
    }

    const handleAddTransaction = () => {
        var theNewDate = getUTCDateFromISOStringDate(dateNew);

        if(theNewDate === undefined){
            setHasError(true);
            setErrorLabel("La date ne peut être nulle");
            return;
        }
        if(theNewDate === 0){
            setHasError(true);
            setErrorLabel("Le montant doit être positif");
            return;
        }

        if(theNewDate < tdy){
            setHasError(true);
            setErrorLabel("Ne peut pas ajouter une opération dans le passé");
            return;
        }

        if(method.label === INVESTMENT) { 
            
            setHasError(false);
            setErrorLabel("");

            var newTransaction = {
                id: lastId+1,
                date: getUTCDateFromISOStringDate(dateNew),
                type: method,
                amount: amountNew
            };
            setLastId(lastId+1);
            addTransactionToArray(newTransaction);
            
        }

        if(method.label === WITHDRAWAL) {
            // calculate the amount available to withdraw on date
            if(amountNew > maxWithdrawal) {
                setHasError(true);
                setErrorLabel(`Retrait maximal de ${toFormatCurrency(maxWithdrawal)} € le ${toFormatDate(dateNew)}`)
                return;
            }
            setHasError(false);
            setErrorLabel("");
            // if ok
            var newTransaction = {
                id: lastId+1,
                date: getUTCDateFromISOStringDate(dateNew),
                type: method,
                amount: amountNew
            };
            setLastId(lastId+1);
            addTransactionToArray(newTransaction);
        }
    }

    const handleDeleteTransaction = (theTransaction) => {
        const updatedTransactions = transactions.filter(t => t.id !== theTransaction.id);
        setTransactions(updatedTransactions);
    }

    return(
        <>
            <WhiteCard>
                <SpaceBetween size="s">
                    <div className="title-second">Constituer un capital pour un projet futur</div>
                    <div className="description-medium">Ajoutez ou retirez des fonds pour voir l'évolution de votre capital</div>

                    <Grid
                        gridDefinition={[
                            {colspan: {default: 12, xs: 4}},
                            {colspan: {default: 12, xs: 4}},
                            {colspan: {default: 12, xs: 4}},
                        ]}
                    >
                        <div style={{display: "flex", alignItems: "end"}}>
                            
                            <CustomInputNumber
                                defaultValue={0}
                                minValue={0}
                                value={amountNew}
                                setValue={setAmountNew}
                                name={"Montant de l'opération"}
                                formatter={(value) => inputCurrencyFormatter(value)}
                                parser={(value) => inputCurrencyParser(value)}
                            />
                            <Button onClick={handleSetMax}>Max</Button>
                        </div>

                        <CustomSelect
                            name={"Sélectionner le type de l'opération"}
                            selectOptions={selectOptions}
                            value={method}
                            setValue={handleChangeMethod}
                        />

                        <CustomDateInput
                            label={"Date de l'opération"}
                            value={dateNew}
                            setValue={handleChangeDate}
                        />

                        <Button onClick={handleAddTransaction}>Ajouter</Button>
                        
                    </Grid>
                </SpaceBetween>
            </WhiteCard>
                
            <WhiteCard>
                <SpaceBetween size="s">
                    <div className="title-second">Evolution du capital</div>
                    <div className="description-medium">Visualisez l'évolution de votre capital</div>
                    
                    <Forecasting data={data}/>
                    
                </SpaceBetween>
            </WhiteCard>

            <WhiteCard>
                <SpaceBetween size="s">
                    <div className="title-second">Transactions</div>
                    <div className="description-medium">Visualisez les transactions effectuées</div>
                    <Table
                        variant="stacked"
                        resizableColumns={true}
                        columnDefinitions={columnsDefinition}
                        items={transactions}
                    />
                </SpaceBetween>
            </WhiteCard>

        </>
    )
}