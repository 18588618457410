import { Box, SpaceBetween } from "@cloudscape-design/components";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import CustomButton from "./CustomButton";


export default function CustomListButton({ handleChangePage, selectedKey, pages }) {



    let availablePixels = window.innerWidth - 200; // remove Sider width
    availablePixels = availablePixels - (20 * 2 * pages.length); // 20px margin on each side
    availablePixels = availablePixels - 20 * 2; // 20px margin on the right and left 

    // if screensize is above 1200px, we need to remove the added margin on the left
    if(window.innerWidth > 1280) {
        availablePixels = availablePixels - 80;
    }

    let percentage = 100 / pages.length;
    consoleOnlyLocally({ percentage })

    let pixels = (availablePixels * percentage) / 100; // Convert percentage to pixels
    consoleOnlyLocally({ pixels });
    
    const newWidth = `${pixels}px`
    return(
        <div style={{margin: "20px"}}>
            <SpaceBetween direction="row" size="xl">
                {pages.map(page =>  CustomButton({ key: page.key, handleChangePage, selectedKey, pages, width : `${newWidth}` }) )}
            </SpaceBetween>
        </div>
    )
}
