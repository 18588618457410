import { ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import ValueWithLabel from "../../customs/ValueWithLabel";
import { toFormatPercent } from "../../utils/toFormat";
import WhiteCard from "../../customs/components/WhiteCard";


export default function Perf21Yield({ info21yield }) {

    return(
    <WhiteCard>
        <SpaceBetween size="l">
        <div className="title-main">Notre méthode de création du rendement</div>
        <div className="description-medium">Nous ciblons le prêt à court terme. Avec une analyse hebdomadaire des portefeuilles de crédit. L'ensemble avec un fort degré d'automatisation.</div>
        <div className="description-medium">La performance que vous récupérez provient du sous-jacent décrit ci-dessous :</div>
        <img 
            src="chaine-financement-du-credit-prive-21yield.png" 
            alt="chaîne du financement du crédit privé - 21yield" 
            width={"80%"}
        />
        
        <div className="subtitle-dark">Notre performance en temps réel</div>
        <div className="description-medium">Ces rendements sont bruts. Il faut ensuite en décompter l'ensemble des coûts financier (commissions de mouvements, virements bancaires internationaux, frais de changes, etc) et les coûts d'opérations.</div>
        <ColumnLayout columns={2}>
            <div className="container-white">
                <ColumnLayout columns={2}>
                    <ValueWithLabel label="Rendement Brut (21yield)">
                        <div className="value-main">{toFormatPercent(info21yield.rawYield)}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="Taux de Défaut (21yield)">
                        <div className="value-main">{toFormatPercent(info21yield.rawDefault)}</div>
                    </ValueWithLabel>
                </ColumnLayout>
            </div>

            <div className="container-white">
                <ColumnLayout columns={2}>
                    <ValueWithLabel label="Rendement Brut (Fintechs)">
                        <div className="value-main">{toFormatPercent(info21yield.borrowerYield)}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="Taux de Défaut (Fintechs)">
                        <div className="value-main">{toFormatPercent(info21yield.borrowerDefault)}</div>
                    </ValueWithLabel>
                </ColumnLayout>
            </div>
        </ColumnLayout>

        <div className="description-medium">Le taux de défaut (Fintechs) correspond au taux de défaut des emprunteurs locaux qui ont souscrit un prêt auprès des fintechs (notre emprunteur).</div>
        <div className="description-medium">Notre métier est d'accompagner la croissance des meilleurs portefeuilles de crédit, d'où notre taux de défaut de {toFormatPercent(info21yield.rawDefault)} et des taux de défauts des portefeuille des fintechs également très bas.</div>
        
    </SpaceBetween>
    </WhiteCard>
    )

}