import { StatusIndicator } from "@cloudscape-design/components";
import { STATUS_REQUESTED, STATUS_COMPLETED, STATUS_PROCESSING } from "../constants/global";

export const CustomStatusIndicator = ({ status }) => {

    if (status === STATUS_COMPLETED) {
      return <StatusIndicator type="success">Completé</StatusIndicator>;
    } 
    if (status === STATUS_REQUESTED) {
      return <StatusIndicator type="pending">Demandé</StatusIndicator>;
    }
    if (status === STATUS_PROCESSING) {
      return <StatusIndicator type="warning">En cours</StatusIndicator>;
    }
    return <StatusIndicator type="error">Erreur</StatusIndicator>;

  };