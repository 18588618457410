import { Box, CopyToClipboard, Grid, SpaceBetween } from "@cloudscape-design/components";
import WhiteCard from "../../customs/components/WhiteCard";
import { formatIban } from "../../utils/toFormat";
import { Button } from "antd";


export default function Investir({ info21yield }){


    return(
    <>
    <WhiteCard>
        <SpaceBetween size="xl">
            <div className="title-second">Le fonctionnement de votre compte est similaire à un compte à terme : signature d'un contrat puis virement SEPA</div>
        
            {/* <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 6}},
                    {colspan: {default: 12, xs: 6}}
                ]}
            >

            </Grid> */}

        </SpaceBetween>
    </WhiteCard>
    
    <WhiteCard>
        <Grid gridDefinition={[
            {colspan: {default: 12, xs: 6}},
            {colspan: {default: 12, xs: 6}}
        ]}>
            <SpaceBetween size="l">
                    <div className="subtitle-dark">1/ Signer le Bulletin de Souscription</div>
                    <div className="description-medium">Ce bulletin sécurise notre engagement et valide votre option et votre taux</div>

                    <div className="description-medium">Pour que nous vous envoyons un bulletin pour signature électronique, veuillez nous envoyer un mail.</div>
            </SpaceBetween>

            <div className="container-no-margin">

                <SpaceBetween size="l">
                <div className="description-medium" style={{ marginRight: '10px' }}>1. Demander un bulletin : précisez si vous souhaitez l'option "rente" ou "placement"</div>
                    <Button 
                        href="mailto:camille@21yield.com?subject=Nouvelle%20Souscription&body=Bonjour Camille,%0D%0A%0D%0AJ'aimerai%20un%20bulletin%20pour%20un%20montant%20de%0D%0A%0D%0AL'option%20choisie%20est"
                        className="button-main"
                    >
                        Envoyer un mail
                    </Button>
                    <div>
                        <div className="description-medium">2. Signer le bulletin qui vous sera envoyé (notre outil de signature électronique est PandaDoc)</div>
                        {/* <button>Télécharger</button> */}
                    </div>

                </SpaceBetween>
            </div>

        </Grid>
    </WhiteCard>

    <WhiteCard>
        <Grid
            gridDefinition={[
                {colspan: {default: 12, xs: 6}},
                {colspan: {default: 12, xs: 6}}
            ]}
        >
            <SpaceBetween size="l">
                <div className="subtitle-dark">2/ Réaliser le virement SEPA</div>                        
                <div className="description-medium">La réception du virement SEPA sur le compte de la société validera votre placement :</div>
                <ul>
                    <li>Le montant du virement doit être égal au montant du placement</li>
                    <li>Le virement doit être réalisé depuis un compte bancaire à votre nom</li>
                    <li>Le virement doit être réalisé en euros</li>
                    <li>Configurer votre virement SEPA depuis votre compte bancaire</li>
                </ul>
            </SpaceBetween>

            <div className="container-no-margin">
                <SpaceBetween size="l">
                    <div className="subtitle-dark">
                        Compte Bancaire de la société : {info21yield.bankName}
                    </div>
                    <div className="description-medium">
                        <CopyToClipboard
                            copyButtonAriaLabel="Copié l'IBAN"
                            copyErrorText="Erreur sur la copie"
                            copySuccessText="IBAN copié"
                            textToCopy={formatIban(info21yield.bankIban)}
                            variant="inline"
                        />
                    </div>
                    <div className="description-medium">
                        <CopyToClipboard
                            copyButtonAriaLabel="Copié le BIC"
                            copyErrorText="Erreur sur la copie"
                            copySuccessText="BIC copié"
                            textToCopy={formatIban(info21yield.bankBic)}
                            variant="inline"
                        />
                    </div>
                </SpaceBetween>
            </div>
        </Grid>
    </WhiteCard>

    <WhiteCard>
        <SpaceBetween size="l">
            <div className="subtitle-dark">3/ Attendre la confirmation de réception (maximum de 2 jours ouvrés)</div>
            <div className="description-medium">
                Vous devez faire votre virement SEPA depuis votre compte de banque en ligne. Nous n'y avons pas d'accès.
            </div>
            <div className="description-medium">Les virements SEPA arrivent en maximum 2 jours ouvrés. Dès réception, notre équipe vous enverra l'ensemble des attestations et documents de suivi</div>
        </SpaceBetween>
    </WhiteCard>
    </>
    )
}