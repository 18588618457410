

export default function downloadPDF(data) {

    const fileBase64 = data.file;
    const downloadFilename = data.filename;

    // Decode the base64 string to binary data
    const byteCharacters = atob(fileBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a link to download the file
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', downloadFilename); // Set the filename
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

  };
