import { ColumnLayout, Grid, SpaceBetween, Table } from "@cloudscape-design/components";
import ValueWithLabel from "../../customs/ValueWithLabel";
import CustomRating from "../../customs/CustomRating";
import { Divider } from "@aws-amplify/ui-react";
import { OPTION_ENTREPRISE } from "../../constants/global_lists";
import { toFormatDate } from "../../utils/toFormat";
import { Button } from "antd";
import WhiteCard from "../../customs/components/WhiteCard";


export default function Account({ account, users }) {

    return(
        <WhiteCard>
            <SpaceBetween size="l">
                <div className="title-main">Compte</div>
                <div className="subtitle-main">Profil de Risque : Performance Equilibrée</div>
                <ColumnLayout columns={4}>

                    <ValueWithLabel label="Pas de volatilité">
                        <CustomRating numberStars={6} />
                    </ValueWithLabel>

                    <ValueWithLabel label="Rendement Fixe">
                    <CustomRating numberStars={6} />
                    </ValueWithLabel>

                    <ValueWithLabel label="Liquidité Rapide">
                    <CustomRating numberStars={4} />
                    </ValueWithLabel>

                    <ValueWithLabel label="Produit Performant">
                    <CustomRating numberStars={6} />
                    </ValueWithLabel>

                </ColumnLayout>

                <Divider />

                <div className="subtitle-main">Informations du Compte</div>
                
                <Grid
                    gridDefinition={[
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 6 } },
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                    ]}
                >
                    <ValueWithLabel label="Nom du Compte">
                        <div className="value-second">{account.name}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Catégorie Compte">
                        <div className="value-second">{account.compteType.label}</div>
                    </ValueWithLabel>
            {/* 
                    <ValueWithLabel label="Type d'Investisseur">
                        <div className="value-second">{account.clientType.label}</div>
                    </ValueWithLabel> */}

                    <ValueWithLabel label="Adresse de Correspondance">
                        <div className="value-second">{account.address}{account.addressComplement && ` ${account.addressComplement}`}, {account.zipCode} {account.city}, {account.country.label}</div>
                    </ValueWithLabel>

                    {account.compteType.value === OPTION_ENTREPRISE.value && (
                        <ValueWithLabel label="Numéro Identification">
                            <div className="value-second">{account.registrationNumber}</div>
                        </ValueWithLabel>
                    )}

                    {account.compteType.value === OPTION_ENTREPRISE.value && (
                        <ValueWithLabel label="Code NAF">
                            <div className="value-second">{account.codeNaf}</div>
                        </ValueWithLabel>
                    )}

                    {account.compteType.value === OPTION_ENTREPRISE.value && (
                        <ValueWithLabel label="Date de Création">
                            <div className="value-second">{toFormatDate(account.dateCreated)}</div>
                        </ValueWithLabel>
                    )}
                </Grid>
                
                <Divider />

                <div className="subtitle-main">Compte Bancaire</div>

                <Grid
                    gridDefinition={[
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                        { colspan: { default: 12, xxs: 3 } },
                    ]}
                >
                    <ValueWithLabel label="IBAN">
                        <div className="value-second">{account.bankIban}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="BIC">
                        <div className="value-second">{account.bankBic}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="Nom de la Banque">
                        <div className="value-second">{account.bankName}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="Compte Bancaire Vérifié">
                        <div className="value-second">{account.bankVerified.label}</div>
                    </ValueWithLabel>
                
                </Grid>

                <Button 
                    href="mailto:contact@21yield.com?subject=Validation%20IBAN&body=Bonjour l'équipe 21yield,%0D%0A%0D%0AVoici%20mon%20IBAN"
                    className="button-main"
                >
                    Envoyer votre IBAN à notre équipe par mail pour modifier votre compte bancaire associé
                    </Button>

                <Divider />

                <div className="subtitle-main">Utilisateurs</div>

                <Table
                    items={users}
                    resizableColumns={true}
                    
                    columnDefinitions={[
                        {
                            id: 'lastname',
                            header: 'Nom',
                            cell: item => item.lastname,
                            isRowHeader: true,
                        },
                        {
                            id: 'firstname',
                            header: 'Prénom',
                            cell: item => item.firstname,
                        },
                        {
                            id: 'username',
                            header: 'Username',
                            cell: item => item.username,
                        },
                        {
                            id: 'nationality',
                            header: 'Nationalité',
                            cell: item => item.nationality.label,
                        }
                    ]}

                />

            </SpaceBetween>
        </WhiteCard>
    
    )
}