import { ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import WhiteCard from "../../customs/components/WhiteCard";
import { useState } from "react";
import { calculateMonthlyRate } from "../../utils/calculatePayout";
import { InputNumber } from "antd";
import { toFormatCurrency } from "../../utils/toFormat";


export default function RenteComplementaire(){

    
    let initRent = 0;
    let initCapital = 0;

    let [rentObjetive, setRentObjective] = useState(0);
    let [investment, setInvestment] = useState(0);

    let [capital, setCapital] = useState(0);
    let [calculatedRent, setCalculatedRent] = useState(0);

    let [annualYield, setAnnualYield] = useState(10);

    const handleChangeRent = (value) => {
        setRentObjective(value);
        var diff = value - initRent;
        var addedCapital = diff/calculateMonthlyRate(annualYield/100);
        setInvestment(addedCapital);
    }

    const handleChangeAmount = (value) => {
        setCapital(value);
        var res = value * calculateMonthlyRate(annualYield/100);
        setCalculatedRent(res);
    }

    const handleChangeAnnualYield = (value) => {
        setAnnualYield(value);
        
        var res = capital * calculateMonthlyRate(value/100) ;
        setCalculatedRent(res);
        
        var diff = rentObjetive - initRent;
        var addedCapital = diff / calculateMonthlyRate(value/100) ;
        setInvestment(addedCapital);
    }

    return(
        <>
            

            <WhiteCard>
                <SpaceBetween size="s">
                    <div className="title-second">Une rente complémentaire consiste à recevoir le paiement des intérêts tous les mois</div>
                    <div className="description-medium">Le capital travaille pour générer cette rente. Les paiements sont réalisé avec les intérêts collectés. Le capital n'est jamais réduit.</div>
                    <div className="description-medium">Lors de votre retrait, vous récupérer l'ensemble du capital placé.</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="description-medium" style={{ marginRight: "10px" }} >Taux annuel du rendement pour les calculs</div>
                        <InputNumber
                            defaultValue={annualYield}
                            min={0}
                            max={15}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value?.replace('%', '')}
                            onChange={handleChangeAnnualYield}
                            style={{backgroundColor: "white"}}
                        />
                    </div>
                </SpaceBetween>
            </WhiteCard>

            <ColumnLayout columns={2}>

                <WhiteCard>
                    <SpaceBetween size="s">
                        <div className="title-second">Si vous avez un objectif de rente</div>
                        <div className="description-medium">Quel est votre objectif de rente mensuelle ?</div>
                        <InputNumber
                            defaultValue={rentObjetive}
                            formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            parser={(value) => value?.replace(/€\s?|( *)/g, '')}
                            onChange={handleChangeRent}
                            style={{backgroundColor: "white", width: "50%"}}
                        />
                        <div className="subtitle-main">Capital à placer : {toFormatCurrency(investment)}</div>
                    </SpaceBetween>
                </WhiteCard>


                <WhiteCard>
                    <SpaceBetween size="s">
                        <div className="title-second">Si vous connaissez votre capital</div>
                        <div className="description-medium">Quel est le montant que vous souhaitez placer ?</div>
                        <InputNumber
                            defaultValue={capital}
                            formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            parser={(value) => value?.replace(/€\s?|( *)/g, '')}
                            onChange={handleChangeAmount}
                            style={{backgroundColor: "white", width: "50%"}}
                        />
                        <div className="subtitle-main">Rente mensuelle : {toFormatCurrency(calculatedRent)}</div>
                    </SpaceBetween>
                </WhiteCard>

            </ColumnLayout>
        
        </>
    )
}