import { postCalltoAPI } from "../axiosCall";


export async function getOperations(sendData) {
    
  var response = await postCalltoAPI( "/get_operations", sendData);
  return response;
}


export async function createDeposit(sendData) {
    
  var response = await postCalltoAPI( "/create_deposit", sendData);
  return response;
}

export async function createCashout(sendData) {
    
  var response = await postCalltoAPI( "/create_cashout", sendData);
  return response;
}

export async function getCashoutsForAsset(sendData) {
    
  var response = await postCalltoAPI( "/get_cashouts_for_asset", sendData);
  return response;
}

export async function deleteOperation(sendData) {
    
  var response = await postCalltoAPI( "/delete_operation", sendData);
  return response;
}