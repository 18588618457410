import { FormField, Input } from "@cloudscape-design/components";
import consoleOnlyLocally from "../utils/consoleOnlyLocally";



export default function CustomInput({ label, value, setValue, placeholder, type = "text" }) {

    function handleChange(event) {
        consoleOnlyLocally(event.detail.value);
        setValue(event.detail.value);
    }

    return(
        <FormField label={label} stretch={true}>
            <Input 
                value={value} 
                onChange={(e) => handleChange(e)}
                placeholder={placeholder}
                fullWidth
                type={type}
            />
        </FormField>
    )
}