import axios from 'axios';

import consoleOnlyLocally from '../utils/consoleOnlyLocally';
import getCurrentUserIdToken from './getCurrentUserIdToken';


export const postCalltoAPI = async (route, data) => {

    var connexionAxios = await createAxiosInstance();
    var resp = await connexionAxios.post(route, data)

    consoleOnlyLocally(`Response from POST API : ${route}`);
    consoleOnlyLocally(resp.data);

    return resp.data
};

export const getCalltoAPI = async (route) => {
    
    var connexionAxios = await createAxiosInstance();
    var resp = await connexionAxios.get(route)

    consoleOnlyLocally(`Response from GET API : ${route}`);
    consoleOnlyLocally(resp.data);

    return resp.data
};


async function createAxiosInstance(){

    let token = await getCurrentUserIdToken();

    var connexionAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    return connexionAxios;
}
