
import { Button } from "antd";
import { DARK_BLUE, LIGHT_GOLD, MAIN_GOLD } from "../../constants/colors";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";


export default function CustomButton({ key, handleChangePage, selectedKey, pages, width}) {

    consoleOnlyLocally({ key, handleChangePage, selectedKey, pages, width })

    return (
        <Button 
            onClick={() => handleChangePage(key)} 
            style={{
                backgroundColor: selectedKey === key ? DARK_BLUE : LIGHT_GOLD,
                color: selectedKey === key ? MAIN_GOLD : DARK_BLUE,
                fontWeight: selectedKey === key ? "bold" : "normal",
                border: selectedKey === key ? `2px solid ${MAIN_GOLD}` : `1px solid ${DARK_BLUE}`,
                borderColor: selectedKey === key ? MAIN_GOLD : DARK_BLUE,
                marginLeft: "20px",
                marginRight: "20px",
                // marginTop: "20px",
                width: width
            }}
        >
            {pages[key].label}
        </Button>
    )
}