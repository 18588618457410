import { useState } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import WhiteCard from "../../customs/components/WhiteCard";
import { downloadFile } from "../../services/calls/files";
import downloadPDF from "../../utils/dwlFile";
import { toFormatDate } from "../../utils/toFormat";
import CustomFilteredTable from "../../customs/table/CustomFilteredTable.js";
import { prepareSelectOptions } from "../../customs/table/prepareSelectOptions.js";
import CustomSelect from "../../customs/CustomSelect.js";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally.js";


export default function Documents({ account, files }) {

    let [isDownloading, setIsDownloading] = useState(false);

    const defaultCategory = { value: '0', label: 'Tout' };
    let [category, setCategory] = useState(defaultCategory);  
    const selectCategoryOptions = prepareSelectOptions('category', defaultCategory, files);

    const rawColumns = [
        {
            id: 'date',
            header: 'Date',
            cell: item => toFormatDate(item.date),
            isRowHeader: true,
            sortingField: 'date',
        },
        {
            id: 'name',
            header: 'Nom',
            cell: item => item.name,
            sortingField: 'name',
        },
        {
            id: 'category',
            header: 'Category',
            cell: item => item.category,
            sortingField: 'category',
        },
        {
            id: 'download',
            header: 'Télécharger',
            cell: item => <Button icon={<DownloadOutlined />} type="default" onClick={() => handleClicDownload(item)}>Télécharger</Button>,
        },
    ]
    
    function matchCategory(item, selectedCategory) {
        const result = selectedCategory.value === defaultCategory.value || item.category === selectedCategory.label;
        return result;
    }

    const filters = [
        {
            defaultValue: defaultCategory,
            selectedOptions: selectCategoryOptions,
            value: category,
            setValue: setCategory,
            matchValue: matchCategory,
        }
    ]

    async function handleClicDownload(file) {
        setIsDownloading(true);
        console.log("Download file", file);

        // retrieve the file from server
        const sendData = { filename: file.name };
        console.log("Send Data", sendData);
        const data = await downloadFile(sendData);

        // download the file to local storage
        downloadPDF(data);

        setIsDownloading(false);
    }

    return(
        <WhiteCard>
            <h1>Coffre fort numérique pour {account.name}</h1>

            {isDownloading && <div>Téléchargement en cours...</div>}

            {!isDownloading && (
                <CustomFilteredTable
                    elements={files}
                    rawColumns={rawColumns}
                    filters={filters}
                >
                    <CustomSelect selectOptions={selectCategoryOptions} value={category} setValue={setCategory} name="Filtre de catégorie" />
                </CustomFilteredTable>
            )}

        </WhiteCard>
    )
}