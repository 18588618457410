import getCurrentUserIdToken from "../services/getCurrentUserIdToken";


function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

export default async function getUserTokenWithRetry() {
    let token = null;
    while (!token) {
      token = await getCurrentUserIdToken().catch(error => {
        console.error("Error retrieving user token:", error);
        return null; // return null if there's an error to trigger another attempt
      });
      if (!token) {
        console.log("Retrying to retrieve user token...");
        await wait(1000); // wait for 1 second before retrying
      }
    }
    return token;
}