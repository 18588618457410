

export default function getAllKeys(data) {

  // Reduce the array of objects to collect all keys
  return data.reduce((keys, item) => {
    // For each item, get the keys and add them to the keys array
    Object.keys(item).forEach(key => {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
    return keys;
  }, []);
}