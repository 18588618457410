import { useState } from "react";

import Investir from "./investir";
import Retirer from "./retirer";
import CustomButton from "../../customs/components/CustomButton";
import CustomListButton from "../../customs/components/CustomListButton";


export default function LayoutTransferts({ info21yield, account, user, cashouts, assets }) {

    const pages = [
        {
            key: "0",
            label: "Investir",
            component: <Investir info21yield={info21yield} />
        },
        {
            key: "1",
            label: "Retirer",
            component: <Retirer account={account} user={user} cashouts={cashouts} assets={assets} />
        }
    ]

    let [selectedPage, setSelectedPage] = useState(pages["0"]);
    let [selectedKey, setSelectedKey] = useState("0");

    function handleChangePage(key) {
        setSelectedPage(pages[key]);
        setSelectedKey(key);
    }

    return(
        <>
            <CustomListButton
                handleChangePage={handleChangePage}
                selectedKey={selectedKey}
                pages={pages}
            />

            {selectedPage.component}
            
        </>
    )
}