import { Button, ConfigProvider, Layout, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { AreaChartOutlined, DashboardOutlined, PieChartOutlined, SwapOutlined, UserOutlined, FileTextOutlined } from "@ant-design/icons";

import CustomLogo from "../customs/components/CustomLogo";
import { DARK_BLUE } from "../constants/colors";
import { toFormatCurrency } from "../utils/toFormat";
import { theme } from "../theme";

import LayoutPerf from "../components/performance/layoutPerf";  
import Account from "../components/compte/Account";
import LayoutComposition from "../components/composition/layoutComposition";
import LayoutTransferts from "../components/transferts/layoutTransferts";
import LayoutPlanners from "../components/planification/LayoutPlanners";
import Documents from "../components/documents/Documents";



export default function Dashboard() { 

    let { user, account, users, operations, cashouts, assets, info21yield, fintechs, files } = useLoaderData();

    const menuItems = [
        {   
            key: "0",
            icon: <DashboardOutlined />,
            label: "Performance",
            // header: "Performance de Votre Portefeuille",
            component: <LayoutPerf account={account} operations={operations} />
        },
        
        {   
            key: "1",
            icon: <SwapOutlined />,
            label: "Transferts",
            // header: "Performance de Votre Portefeuille",
            component: <LayoutTransferts info21yield={info21yield} account={account} user={user} cashouts={cashouts} assets={assets} />
        },
        
        {   
            key: "2",
            icon: <AreaChartOutlined />,
            label: "Planification",
            // header: "Performance de Votre Portefeuille",
            component: <LayoutPlanners />
        },
        
        {   
            key: "3",
            icon: <UserOutlined />,
            label: "Compte",
            // header: "Performance de Votre Portefeuille",
            component: <Account account={account} users={users} />
        },

        {
            key: "4",
            icon: <FileTextOutlined />,
            label: "Documents",
            // header: "Performance de Votre Portefeuille",
            component: <Documents account={account} files={files} />
        },
        
        {   
            key: "5",
            icon: <PieChartOutlined />,
            label: "Composition",
            // header: "Performance de Votre Portefeuille",
            component: <LayoutComposition fintechs={fintechs} info21yield={info21yield} />
        }
    ]

    let [selectedItem, setSelectedItem] = useState(menuItems[0]);
    
    function handleSelect({key}) {
        setSelectedItem(menuItems[key]);
    }

    return(
    <ConfigProvider
        theme={theme}
    >
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                style={{minHeight: "100vh", backgroundColor: DARK_BLUE}}
            >
                <CustomLogo maxWidth="50px" />
                <div className="container-sidebar">
                    <div>Valeur Totale</div>
                    <div style={{fontSize: "20px"}}>{toFormatCurrency(account.totalBalance)}</div>
                </div>
                <Menu 
                    theme="dark" 
                    mode="inline" 
                    defaultSelectedKeys={["0"]} 
                    items={menuItems} 
                    onSelect={handleSelect}
                    style={{backgroundColor: DARK_BLUE}}
                />

                {/* Add a button on the bottom of the sidebar to signout */}
                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button 
                        onClick={() => signOut()}
                        style={{position: "absolute", bottom: "50px"}}
                    >
                        Déconnexion
                    </Button>
                </div>

            </Sider>

            <Layout>
                <Content>
                    {selectedItem.component}
                </Content>
            </Layout>
        </Layout>
    </ConfigProvider>
    )
}