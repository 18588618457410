import { NavLink, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <div className="div-fullscreen">
        <div className="div-container">
          <h1>Oops!</h1>
          <p>Un tech a fait une erreur ou n'a pas fini de coder !</p>
          <p>Nous le virons rapidement pour que cela ne se reproduise plus.</p>
          <NavLink to="/">Revenir au tableau de bord</NavLink>
        </div>
      </div> 
    </div>
  );
}
