
export const OPTION_ENTREPRISE = {value: "1", label: "Entreprise"};
export const OPTION_PARTICULIER = {value: "2", label: "Particulier"};

export const LIST_TYPE_COMPTE = [
    OPTION_ENTREPRISE,
    OPTION_PARTICULIER,
];

export const OPTION_RETAIL = {value: "1", label: "Retail"};
export const OPTION_PROFESSIONEL = {value: "2", label: "Professionel"}

export const LIST_TYPE_CLIENT = [
    OPTION_RETAIL,
    OPTION_PROFESSIONEL,
];

export const OPTION_AUTHORIZED = { value: '1', label: 'Authorized' };
export const OPTION_UNAUTHORIZED = { value: '2', label: 'Unauthorized' };

export const LIST_CATEGORY_AUTHORIZED = [
    OPTION_AUTHORIZED,
    OPTION_UNAUTHORIZED
]

export const OPTION_DISTRIBUTION = { value: '1', label: 'Distribution' };
export const OPTION_CAPITALISATION = { value: '2', label: 'Capitalisation' };

export const LIST_CATEGORY_INVESTMENT = [
    OPTION_DISTRIBUTION,
    OPTION_CAPITALISATION
]

export const CATEGORY_INVESTMENT = "INVESTMENT";
export const CATEGORY_CASHOUT = "CASHOUT";
export const CATEGORY_PAYMENT = "PAYMENT";

export const OPTION_INVESTMENT = { value: "1", label: CATEGORY_INVESTMENT };
export const OPTION_CASHOUT = { value: "2", label: CATEGORY_CASHOUT };
export const OPTION_PAYMENT = { value: "3", label: CATEGORY_PAYMENT };

export const LIST_CATEGORY_ADD_OPERATION = [
    OPTION_INVESTMENT,
    OPTION_CASHOUT,
    OPTION_PAYMENT
];

export const OPTION_ACTIVE = { value: '1', label: 'Actif' };
export const OPTION_TERM_SHEET = { value: '2', label: 'Term Sheet' };
export const OPTION_DISCUSSION = { value: '3', label: 'Discussion' };
export const OPTION_POSTPONED = { value: '3', label: 'Reporté' };

export const LIST_STATUS = [
    OPTION_ACTIVE,
    OPTION_TERM_SHEET,
    OPTION_DISCUSSION,
    OPTION_POSTPONED
]