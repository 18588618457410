import { useState } from "react";
import WhiteCard from "../../customs/components/WhiteCard";
import { calculateMonthlyRate, calculateMonthlyReinvestForRetirementRent } from "../../utils/calculatePayout";
import { SpaceBetween, Grid, ColumnLayout } from "@cloudscape-design/components";
import { InputNumber } from "antd";
import { toFormatCurrency } from "../../utils/toFormat";
import { Divider } from "@aws-amplify/ui-react";


export default function PreparationRetraite() {

    let initRent = 2000;

    let [annualYield, setAnnualYield] = useState(10);
    
    let [ageRetire, setAgeRetire] = useState(50);
    let [currentAge, setCurrentAge] = useState(35);
    let [duration, setDuration] = useState(ageRetire - currentAge);

    let [objective, setObjective] = useState(initRent);
    let [reinvest, setReinvest] = useState(calculateMonthlyReinvestForRetirementRent(annualYield/100, initRent, duration));

    const changeReinvest = (dur, obj) => {  
        if(dur > 0 && obj > 0) {
            let res = calculateMonthlyReinvestForRetirementRent(annualYield/100, obj, dur);
            setReinvest(res);
        }
    }

    const handleChangeAgeRetire = (value) => {
        setAgeRetire(value);
        if(currentAge !== null){
            let dur = value - currentAge;
            setDuration(dur);
            changeReinvest(dur, objective);
        }
    }

    const handleChangeCurrentAge = (value) => {
        setCurrentAge(value);
        if(ageRetire !== null){
            let dur = ageRetire - value;
            setDuration(dur);
            changeReinvest(dur, objective);
        }
    }

    const handleChangeObjective = (value) => {
        setObjective(value);
        changeReinvest(duration, value);
    }

    const handleChangeAnnualYield = (value) => {
        setAnnualYield(value);
        let res = calculateMonthlyReinvestForRetirementRent(value/100, objective, duration);
        setReinvest(res);
    }

    return(
    <>
        <WhiteCard>
            <SpaceBetween size="s">
                <div className="title-second">Préparer votre retraite en ajoutant du capital tous les mois</div>
                <div className="description-medium">Le capital travaille pour constituer un capital dont le paiements des intérêts vous produira une rente complémentaire.</div>
                <div className="description-medium">Lors de votre retrait, vous récupérer l'ensemble du capital placé.</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="description-medium" style={{ marginRight: "10px" }} >Taux annuel du rendement pour les calculs</div>
                    <InputNumber
                        defaultValue={annualYield}
                        min={0}
                        max={15}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '')}
                        onChange={handleChangeAnnualYield}
                        style={{backgroundColor: "white"}}
                    />
                </div>
            </SpaceBetween>
        </WhiteCard>

        <ColumnLayout columns={2}>
            <WhiteCard>
                <SpaceBetween size="s">
                    <div className="title-second">Préparer votre retraite</div>

                    <div className="description-medium">Quel est votre âge actuel ?</div>
                    <InputNumber
                        defaultValue={currentAge}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} ans`}
                        parser={(value) => value?.replace('ans', '')}
                        onChange={handleChangeCurrentAge}
                        style={{backgroundColor: "white"}}
                    />
                    <div className="description-medium">À quel âge souhaitez-vous prendre votre retraite ?</div>
                    <InputNumber
                        defaultValue={ageRetire}
                        min={0}
                        max={100}
                        formatter={(value) => `${value} ans`}
                        parser={(value) => value?.replace('ans', '')}
                        onChange={handleChangeAgeRetire}
                        style={{backgroundColor: "white"}}
                    />
                    <div className="description-medium">Quel est votre objectif de rente mensuelle ?</div>
                    <InputNumber
                        defaultValue={objective}
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        parser={(value) => value?.replace(/€\s?|( *)/g, '')}
                        onChange={handleChangeObjective}
                        style={{backgroundColor: "white"}}
                    />

                </SpaceBetween>
            </WhiteCard>


            <WhiteCard>
                <SpaceBetween size="m">
                    <div className="title-second">Bilan</div>

                    <div className="subtitle-main">Investir : {toFormatCurrency(reinvest)} /mois</div>
                    <div className="description-medium">De vos à {currentAge} ans vos {ageRetire} ans</div>
                    <div className="description-small">Soit au total {toFormatCurrency(duration * 12 * reinvest)} de placé</div>

                    <Divider />

                    <div className="title-second">Votre retraite</div>
                    <div className="subtitle-main">Rente : {toFormatCurrency(objective)} /mois</div>
                    <div className="subtitle-main">Capital : {toFormatCurrency(objective/calculateMonthlyRate(annualYield/100))}</div>
                    <div className="description-small">Votre rente est payée sans toucher à votre capital</div>
                    
                </SpaceBetween>
            </WhiteCard>

        </ColumnLayout>


    </>
    )
}