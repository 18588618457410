import { Amplify } from '@aws-amplify/core';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';


export default async function getCurrentUserIdToken() {

  const tokens = await Amplify.Auth.getTokens();
  let token = tokens.idToken.toString();

  return token;
}
