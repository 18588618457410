import { Button, SpaceBetween, Table } from "@cloudscape-design/components";
import WhiteCard from "../../customs/components/WhiteCard";
import AddCashout from "./addCashout";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import { useState } from "react";
import { deleteOperation } from "../../services/calls/operation";
import LoadingPage from "../../loading/Loading";
import { toFormatCurrency, toFormatDate } from "../../utils/toFormat";
import { CustomStatusIndicator } from "../../customs/CustomStatusIndicator";
import { STATUS_REQUESTED } from "../../constants/global";


export default function Retirer({ account, user, cashouts, assets }){
    
    let [isLoading, setIsLoading] = useState(false);

    consoleOnlyLocally(cashouts);

    async function handleDeleteCashout(item) {
        
        setIsLoading(true);
        
        await deleteOperation({operationID: item.operationID});
        
        window.location.reload();
        setIsLoading(false);
    }

    if(isLoading) return <LoadingPage />;

    function ListCashoutsTable() {

        return (
            <div style={{margin: "20px"}}>
            <Table
                variant="stacked"
                resizableColumns={true}

                // header={<Header variant="h3">Prévisionnel Financier</Header>}
                columnDefinitions={[
                    {
                        id: 'date',
                        header: 'Date',
                        cell: item => toFormatDate(item.onDate),
                        isRowHeader: true,
                    },
                    {
                        id: 'value',
                        header: 'Montant',
                        cell: item => toFormatCurrency(item.valueEur),
                    },
                    {
                        id: 'produit financier',
                        header: 'Produit Financier',
                        cell: item => item.assetName,
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        cell: item => <CustomStatusIndicator status={item.status} />,
                    },
                    {
                        id: 'delete',
                        header: 'Suppression',
                        cell: item => item.status === STATUS_REQUESTED && <div className="icon-container"><Button variant="inline-icon" iconName="remove" onClick={() => handleDeleteCashout(item)}/> </div>,
                    },
                    // {
                    //     id: 'gainPercent',
                    //     header: 'Gain Cumulé (en %)',
                    //     cell: item => <div className="text-gain">+ {toFormatPercent(item.gainPercent)}</div>,
                    // },
                ]}
                items={cashouts}
            />
        </div>
        );
    }

    return(<>
        <WhiteCard>
            <SpaceBetween size="s">
                <div className="title-second">Les retraits sont tous fait par virement SEPA</div>
                <div className="subtitle-dark">Vous nous indiquez le montant et la date, nous vous faisons le virement SEPA</div>
                <div className="description-medium">Les retraits sont à prévoir le trimestre précédent. Vous pouvez retirer à tout moment, sans frais ni pénalités</div>
            </SpaceBetween>
        </WhiteCard>
        
        <WhiteCard>
            <AddCashout user={user.userID} account={account} assets={assets} cashouts={cashouts} />
        </WhiteCard>

    
        <ListCashoutsTable />

    </>)
}