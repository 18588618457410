import { DARK_BLUE, MAIN_GOLD } from "./constants/colors";


export const theme = {
    token: {
        // Seed Token
        colorPrimary: MAIN_GOLD,
        colorTextBase: DARK_BLUE,
        borderRadius: 2,

    },
}