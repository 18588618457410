import WhiteCard from "../../../customs/components/WhiteCard";
import { Box, ColumnLayout, Grid, SpaceBetween, Table } from "@cloudscape-design/components";
import ValueWithLabel from "../../../customs/ValueWithLabel";
import { toFormatCurrency, toFormatDate, toFormatPercent } from "../../../utils/toFormat";
import { getUTCDateFromISOStringDate, getUTCDateNow } from "../../../utils/dateUtils";
import { useState } from "react";
import CustomDateInput from "../../../customs/CustomDateInput";
import { calculateCompoundedValue } from "../../../utils/calculatePayout";
import consoleOnlyLocally from "../../../utils/consoleOnlyLocally";


export default function Placement({ account }) {

    let [selectedDate, setSelectedDate] = useState(getUTCDateNow().toISOString());
    let [estimatedValue, setEstimatedValue] = useState(0);
    let [estimatedGain, setEstimatedGain] = useState(0);

    function calculateInvestmentForecastValues() {
        const today = getUTCDateNow();
        const years = 10;
        const values = [];
        const balance = account.balanceInvestment;
        const annualYield = account.investmentAnnualYield;

        for (let i = 0; i < years; i++) {
            const value = balance * Math.pow(1 + annualYield, i);
            const gain = value - balance;
            const gainPercent = gain / balance;
            values.push({
                date: toFormatDate(new Date(Date.UTC(today.getFullYear() + i, today.getMonth(), today.getDate()))),
                duration: i,
                value,
                gain,
                gainPercent
            });
        }
        return values;
    }

    let tableInvestmentForecastValues = calculateInvestmentForecastValues();

    function handleChangeDate(date) {

        var newValue = calculateCompoundedValue(getUTCDateNow(), getUTCDateFromISOStringDate(date),  account.investmentAnnualYield, account.balanceInvestment)
        setEstimatedValue(newValue);
        var newGain = newValue - account.balanceInvestment;
        consoleOnlyLocally(newGain);

        setEstimatedGain(newGain);

        setSelectedDate(date);
        

    }
    
    return(
    <>
        <WhiteCard>
            <SpaceBetween size="m">
                <Grid
                    gridDefinition={[
                        {colspan: {default: 12, xs: 4}},
                        {colspan: {default: 12, xs: 4}},
                        {colspan: {default: 12, xs: 4}}
                    ]}
                >
                    <ValueWithLabel
                        label="Valeur (EUR)"
                    >
                        <div className="description-small">Valeur du capital en date du jour, le {toFormatDate(getUTCDateNow())}</div>
                        <div className="value-main">{toFormatCurrency(account.balanceInvestment)}</div>
                    </ValueWithLabel>

                    <ValueWithLabel
                        label="Rendement annuel (%)"
                    >
                        <div className="description-small">Taux annuel auquel votre capital travaille</div>
                        <div className="value-main">{toFormatPercent(account.investmentAnnualYield)}</div>
                    </ValueWithLabel>

                    <ValueWithLabel
                        label="Total de Bénéfices (EUR)"
                    >
                        <div className="description-small">Sommes de tous vos gains</div>
                        <div className="value-main">{toFormatCurrency(account.totalEarned)}</div>
                    </ValueWithLabel>

                </Grid>                                
            </SpaceBetween>
        </WhiteCard>

        <WhiteCard>
            <SpaceBetween size="s">
                <Box variant="awsui-key-label">Prévisionnel</Box>
                <div className="description-medium">
                    Votre capital travaille au taux de {toFormatPercent(account.investmentAnnualYield)}. 
                    En appliquant ce taux à la valeur actuelle du placement {toFormatCurrency(account.balanceInvestment)}, votre capital atteindra les montants suivants :
                </div>
                
                <Table
                    variant="stacked"
                    resizableColumns={true}

                    // header={<Header variant="h3">Prévisionnel Financier</Header>}
                    columnDefinitions={[
                        {
                            id: 'date',
                            header: 'Date',
                            cell: item => item.date,
                            isRowHeader: true,
                        },{
                            id: 'duration',
                            header: 'Durée (année)',
                            cell: item => item.duration,
                        },
                        {
                            id: 'value',
                            header: 'Valeur Prévisionnelle',
                            cell: item => toFormatCurrency(item.value),
                        },
                        {
                            id: 'gain',
                            header: 'Gain Cumulé (en EUR)',
                            cell: item => <div className="text-gain">+ {toFormatCurrency(item.gain)}</div>,
                        },
                        {
                            id: 'gainPercent',
                            header: 'Gain Cumulé (en %)',
                            cell: item => <div className="text-gain">+ {toFormatPercent(item.gainPercent)}</div>,
                        },
                    ]}
                    items={tableInvestmentForecastValues}
                />
               
            </SpaceBetween>
        </WhiteCard>
        
        <WhiteCard>
            <ColumnLayout columns={3}>

                <div>
                    <Box variant="awsui-key-label">Calculer votre capital à une date spécifique</Box>
                    <div className="description-small">Entrez une date pour calculer la valeur de votre portefeuille à cette date</div>
                    <CustomDateInput
                        // label="Date"
                        value={selectedDate}
                        setValue={handleChangeDate}
                    />
                </div>

                <ValueWithLabel
                    label="Valeur (EUR)"
                >
                    <div className="description-small">Valeur du capital à la date sélectionnée</div>
                    <div className="value-main">{toFormatCurrency(estimatedValue)}</div>
                </ValueWithLabel>

                <ValueWithLabel
                    label="Gain (EUR)"
                >
                    <div className="description-small">Valeur du gain à la date sélectionnée</div>
                    <div className="value-main">+ {toFormatCurrency(estimatedGain)}</div>
                </ValueWithLabel>

            </ColumnLayout>
        </WhiteCard>
            
    </>)
}