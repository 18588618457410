import {RouterProvider, createBrowserRouter, redirect  } from 'react-router-dom'
import { SpaceBetween } from '@cloudscape-design/components';

import { I18n } from "@aws-amplify/core";
import { Amplify } from 'aws-amplify';
import { translations, Authenticator } from '@aws-amplify/ui-react';
import awsExports from "./aws-exports";
import '@aws-amplify/ui-react/styles.css';
import "./custom-auth.css"
import { signOut } from 'aws-amplify/auth';

import './App.css';
import './theme.css';

import ErrorPage from "./error-page";
import LoadingPage from './loading/Loading';
import Maintenance from './routes/Maintenance';
import Dashboard from './routes/Dashboard';
import { dashboardLoader } from './loaders/dashboardLoader';

Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Reset Password': 'Choisir un nouveau Mot de Passe',
    'Enter your Email': "Email",
    'Enter your Password': 'Mot de passe',
    Email: "Entrez votre email",
    Password: 'Entrez votre mot de passe',
    'Forgot your password?': 'Choisir un nouveau Mot de Passe',
    'Back to Sign In': 'Revenir à la page de connexion',
  },
});


/*----------  Form Data  ------------------------------------------------ 

config: Object with the configuration of the form

account: Account object from DynamoDB as Json

users: Array of User object from DynamoDB as Json

currentUser : User object from DynamoDB as Json

assets: Array of Asset object from DynamoDB as Json

availableAssets: Array of Asset object from DynamoDB as Json

nameListAvailableAssets: String of the name of the List of Available Assets

operations: Array of Operation object from DynamoDB as Json

deposits: Array of Deposit object from DynamoDB as Json

withdrawals: Array of Withdrawal object from DynamoDB as Json

cashouts: Array of Cashout object from DynamoDB as Json

investments: Array of Investment object from DynamoDB as Json

info21yield: Array of Info21yield object from DynamoDB as Json

---------------------------------------------------------------------------  */


export default function App() {
  
  const router = createBrowserRouter([
    {
      path: "/",
      loader: dashboardLoader,
      Component: Dashboard, 
      errorElement: <ErrorPage />,
    },
    {
      path: "/maintenance",
      Component: Maintenance
    },
    {
      path: "/logout",
      async action() {
        // We signout in a "resource route" that we can hit from a fetcher.Form
        signOut();
        return redirect("/");
      },
    }
  ]);
      
  const components = {
    Header() {
        return (
          <SpaceBetween>
            <div className='login-container'>
              <div className='login-img' />
              <div className='login-text'>
                21yield - Suivi de Portefeuille - v3
              </div>
            </div>
          </SpaceBetween>
        );
    },

    Footer() {
        return (
          <div className='footer-container'>
            <div className="footer-text">21yield&copy; Copyright, tous droits réservés</div>
          </div>
        );
    },
  }

  return (
    <Authenticator hideSignUp={true} components={components}>
      <RouterProvider router={router} fallbackElement={<LoadingPage />} />
    </Authenticator>  
  );
}