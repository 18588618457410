import {
    FormField,
    Select
  } from '@cloudscape-design/components';
import consoleOnlyLocally from "../utils/consoleOnlyLocally";



export default function CustomSelect({ selectOptions, value, setValue, name }) {

    function handleChange(event) {
        consoleOnlyLocally(event.detail.selectedOption);
        setValue(event.detail.selectedOption);
    }
    
    return(
        <div className="select-filter">
            <FormField label={`${name}`}>
                <Select
                    data-testid={`${name}-filter`}
                    options={selectOptions}
                    selectedAriaLabel="Selected"
                    selectedOption={value}
                    onChange={event => { handleChange(event); }}
                    ariaDescribedby={null}
                    expandToViewport={true}
                />
            </FormField>
        </div>
    )
}