import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';


export default function LoadingPage() {

    const [text, setText] = useState('Consolidation des indicateurs et de la performance');
    let [iconSelected, setIconSelected] = useState(<LockOutlined />);

    const texts = [
        "Récupération de vos données en cours, merci de patienter...",
        "Préparation de votre tableau de bord, ça arrive...",
        "Nous travaillons dur pour récupérer vos données, restez avec nous...",
        "Votre tableau de bord est en préparation, un instant s'il vous plaît...",
        "Nous sommes en train de peaufiner votre tableau de bord, merci de patienter...",
        "Récupération de vos données en cours, nous y sommes presque...",
        "Nous préparons votre tableau de bord avec soin, merci de patienter...",
        "Nous sommes en train de rassembler vos données, restez avec nous...",
        "Votre tableau de bord est en cours de préparation, ça arrive...",
        "Nous travaillons à toute vitesse pour récupérer vos données, merci de patienter...",
        "Nous sommes en train de préparer votre tableau de bord, restez avec nous...",
        "Récupération de vos données en cours, nous y sommes presque...",
        "Nous préparons votre tableau de bord avec attention, merci de patienter...",
        "Nous sommes en train de compiler vos données, restez avec nous...",
        "Votre tableau de bord est en cours de préparation, nous y sommes presque...",
        "Nous travaillons sans relâche pour récupérer vos données, merci de patienter...",
        "Nous sommes en train de finaliser votre tableau de bord, restez avec nous...",
        "Récupération de vos données en cours, ça arrive...",
        "Nous préparons votre tableau de bord avec dévouement, merci de patienter...",
        "Nous sommes en train de traiter vos données, restez avec nous..."
        // Add more texts as needed
    ];

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            index = (index + 1) % texts.length;
            setText(texts[index]);
            setIconSelected(iconSelected === <LockOutlined /> ? <UnlockOutlined /> : <LockOutlined />);
        }, 1000);

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);

    return (
    <div className="div-fullscreen">
        <div className="div-container">
            <h1 className="h1-title">Récupération des données</h1>
            <p>{text}</p>
            <div className="icon-container">
                <div className='lock-icon'>{iconSelected}</div>
            </div>
            <div className="spinner-container">
                <div className="loading-spinner"></div>
            </div>
        </div>
    </div>
    )
}