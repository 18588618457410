import { useState } from "react";
import CustomButton from "../../customs/components/CustomButton";
import RenteComplementaire from "./renteComplementaire";
import CapitalConstitution from "./capitalConstitution";
import PreparationRetraite from "./preparationRetraite";
import CustomListButton from "../../customs/components/CustomListButton";


export default function LayoutPlanners({ }) {

    
    const pages = [
        {
            key: "0",
            label: "Générer une rente complémentaire",
            component: <RenteComplementaire />
        },
        {
            key: "1",
            label: "Constituer un capital pour un projet futur",
            component: <CapitalConstitution />
        },
        {
            key: "2",
            label: "Préparer sa retraite",
            component: <PreparationRetraite />
        }
    ]

    let [selectedPage, setSelectedPage] = useState(pages["0"]);
    let [selectedKey, setSelectedKey] = useState("0");

    function handleChangePage(key) {
        setSelectedPage(pages[key]);
        setSelectedKey(key);
    }


    return(
        <div>
            <CustomListButton
                handleChangePage={handleChangePage}
                selectedKey={selectedKey}
                pages={pages}
            />
            
            {selectedPage.component}
        </div>
    )
}