

export default function CustomLogo({maxWidth="50px"}) {
    return (
        <>
            <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center", paddingTop:"20px"}}>
                <img src="/logo192.png" alt="logo" className="logo" style={{maxWidth:maxWidth}} />
            </div>
            {/* <div style={{textAlign: "center"}}>
                <div className="subtitle-main">Performance et Liquidité</div>
            </div> */}
        </>
    )
}