import { BarChart, Box, Grid, SpaceBetween, Table } from "@cloudscape-design/components";
import WhiteCard from "../../../customs/components/WhiteCard";
import ValueWithLabel from "../../../customs/ValueWithLabel";
import { toFormatCurrency, toFormatDate, toFormatPercent } from "../../../utils/toFormat";
import { CATEGORY_PAYMENT, OPTION_PARTICULIER } from "../../../constants/global_lists";
import { Tooltip } from "antd";
import { getUTCDateNow } from "../../../utils/dateUtils";
import { GOLD_MAIN } from "../../../constants/global";
import { CustomStatusIndicator } from "../../../customs/CustomStatusIndicator";
import consoleOnlyLocally from "../../../utils/consoleOnlyLocally";
import getPfu from "../../../utils/getPfu";


export default function Rente({ account, operations }) {

    consoleOnlyLocally(account)
    // filter only operations that are category PAYMENT
    let payments = operations.filter(operation => operation.category === CATEGORY_PAYMENT);
    
    const columnDefinitions = [
        {
            id: 'date',
            header: 'Date',
            cell: item => toFormatDate(item.onDate),
            isRowHeader: true,
        },
        {
            id: 'value',
            header: 'Montant',
            cell: item => toFormatCurrency(item.valueEur),
        },
        {
            id: 'category',
            header: 'Category',
            cell: item => item.category,
        },
        {
            id: 'status',
            header: 'Status',
            cell: item => <CustomStatusIndicator status={item.status} />,
        }
    ]

    let { shouldApplyPFU, taxeRate } = getPfu(account);

    let paidRent = account.monthlyPayment * ( 1 - taxeRate );

    const descriptionFiscalite = "Le Prélèvement Forfaitaire Unique (PFU) est un impôt de 30% sur les revenus du capital. Le PFU est appliqué sur les revenus du capital (dividendes, intérêts, plus-values de cession de valeurs mobilières, etc.). Le PFU est prélevé à la source par l'établissement financier qui verse les revenus."
    
    let data = []
    let xDomain = []
    let tdy = getUTCDateNow();
    let nextPaymentDate = new Date(tdy.getFullYear(), tdy.getMonth(), 5);
    // if today is the 5th of the month, add a month to nexPaymentDate
    if(tdy.getDate() === 5){
        nextPaymentDate = new Date(nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1));
    } 

    let k = 0;

    let nextPayment = account.nextMonthlyPayment * ( 1 - taxeRate );

    data.push({ x: nextPaymentDate, y: nextPayment });
    xDomain.push(nextPaymentDate);

    nextPaymentDate = new Date(nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1));
    k++;

    while (k < 11) {
        data.push({ x: nextPaymentDate, y: paidRent });
        xDomain.push(nextPaymentDate);
        
        nextPaymentDate = new Date(nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1));
        k++;
    }

    // Create a funtction that plot a histogram of the paidRent paid on each 5th of the month
    // The histogram should be plotted on the second white card
    function plotHistogram() {
        
        return(
        <BarChart
            hideFilter={true}
            hideLegend={true}
            series={[
              {
                type: "bar",
                data: data,
                color: GOLD_MAIN,
                valueFormatter: e =>
                  e.toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) + " €",
              }
            ]}
            xDomain={xDomain}
            // yDomain={[0, paidRent]}
            i18nStrings={{
              xTickFormatter: e =>
                e
                  .toLocaleDateString("fr-FR", {
                    month: "short",
                    day: "numeric",
                    year: "numeric"
                  })
                  .split(",")
                  .join("\n"),
            //   yTickFormatter: function numberFormatter(e) {
            //     return Math.abs(e) >= 1e9
            //       ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
            //           "G"
            //       : Math.abs(e) >= 1e6
            //       ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
            //         "M"
            //       : Math.abs(e) >= 1e3
            //       ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
            //         "K"
            //       : e.toFixed(2);
            //   }
            }}
            height={300}
            // xTitle="Time (UTC)"
            // yTitle="Revenue (USD)"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No data available</b>
                <Box variant="p" color="inherit">
                  There is no data available
                </Box>
              </Box>
            }
          />
        );
    }


    return(
    <>
        <WhiteCard>
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}},
                    {colspan: {default: 12, xs: shouldApplyPFU ? 2 : 3}}
                ]}
            >
                <ValueWithLabel
                    label="Capital (EUR)"
                >
                    <div className="description-small">Montant de capital qui travaille</div>
                    <div className="value-main">{toFormatCurrency(account.balancePension)}</div>
                </ValueWithLabel>

                <ValueWithLabel
                    label="Rendement annuel (%)"
                >
                    <div className="description-small">Taux annuel de rendement</div>
                    <div className="value-main">{toFormatPercent(account.pensionAnnualYield)}</div>
                </ValueWithLabel>
                
                <ValueWithLabel
                    label="Total des versements (EUR)"
                >
                    <div className="description-small">Total déjà payé</div>
                    <div className="value-main">{toFormatCurrency(account.totalPaid)}</div>
                </ValueWithLabel>
                {shouldApplyPFU && (
                <ValueWithLabel
                    label="Rente Mensuelle Brute (EUR)"
                >
                    <div className="description-small">Rente mensuelle brute</div>
                    <div className="value-main">{toFormatCurrency(account.monthlyPayment)}</div>
                </ValueWithLabel>)}

                {shouldApplyPFU && (
                
                    <ValueWithLabel
                        label="Fiscalité Payée (EUR)"
                    >
                        <Tooltip title={descriptionFiscalite}><div className="description-small">Fiscalié payée (PFU)*</div></Tooltip>
                        <div className="value-main">{toFormatCurrency(account.monthlyPayment * 0.3)}</div>
                    </ValueWithLabel>
                )}

                <ValueWithLabel
                    label="Rente Mensuelle (EUR)"
                >
                    <div className="description-small">Votre rente mensuelle</div>
                    <div className="value-main">{toFormatCurrency(paidRent)}</div>
                </ValueWithLabel>
            </Grid> 
        </WhiteCard>
                
        <WhiteCard>
            <SpaceBetween size="l">
                
                <div className="title-second">Les prochains paiements de votre rente mensuelle (EUR)</div>

                {plotHistogram()}

            </SpaceBetween>
        </WhiteCard>

        <WhiteCard>
            <SpaceBetween size="xl">
                
                <div className="title-second">L'historique des paiements déjà réalisés</div>
                
                <Table
                    variant="stacked"
                    resizableColumns={true}
                    columnDefinitions={columnDefinitions}
                    items={payments}
                />

            </SpaceBetween>

        </WhiteCard>
    </>
    )
}