import { Button, SpaceBetween, Table } from "@cloudscape-design/components";
import { toFormatCurrency, toFormatDate } from "../../../utils/toFormat";
import { CustomStatusIndicator } from "../../../customs/CustomStatusIndicator";
import { STATUS_REQUESTED } from "../../../constants/global";
import { deleteOperation } from "../../../services/calls/operation";
import { useState } from "react";
import consoleOnlyLocally from "../../../utils/consoleOnlyLocally";
import LoadingPage from "../../../loading/Loading";
import WhiteCard from "../../../customs/components/WhiteCard";


export default function Operations({ account, operations }) {

    let [isLoading, setIsLoading] = useState(false);

    const columnDefinitions = [
        {
            id: 'date',
            header: 'Date',
            cell: item => item.onDate ? toFormatDate(item.onDate) : toFormatDate(item.dateSigned),
            isRowHeader: true,
        },
        {
            id: 'value',
            header: 'Montant',
            cell: item => toFormatCurrency(item.valueEur),
        },
        {
            id: 'category',
            header: 'Category',
            cell: item => item.category,
        },
        {
            id: 'produit financier',
            header: 'Produit Financier',
            cell: item => item.assetName,
        },
        {
            id: 'status',
            header: 'Status',
            cell: item => <CustomStatusIndicator status={item.status} />,
        },
        {
            id: 'delete',
            header: 'Suppression',
            cell: item => item.status === STATUS_REQUESTED && <div className="icon-container"><Button variant="inline-icon" iconName="remove" onClick={() => handleDeleteOperation(item)}/> </div>,
        },
    ]
    
    async function handleDeleteOperation(item) {

        setIsLoading(true);
    
        consoleOnlyLocally("item")
        consoleOnlyLocally(item.operationID)

        await deleteOperation({operationID: item.operationID});
        
        window.location.reload();
        setIsLoading(false);
    }

    if(isLoading) return <LoadingPage />

    return(
        <WhiteCard>
        <SpaceBetween size="xl">
            
            <div className="title-second">L'historique de vos opérations</div>
            
            <Table
                variant="stacked"
                resizableColumns={true}
                columnDefinitions={columnDefinitions}
                items={operations}
            />

        </SpaceBetween>

        </WhiteCard>
    )
}