import { getCalltoAPI, postCalltoAPI } from "../axiosCall";


export async function listFiles() {
    
    var response = await getCalltoAPI( "/list_files");
    return response;
}

export async function downloadFile(sendData) {

    var response = await postCalltoAPI( "/dwl_file", sendData);
    return response;
}
      
      