import { FormField } from '@cloudscape-design/components';
import { InputNumber } from 'antd';


export default function CustomInputNumber({ defaultValue, value, minValue=0, setValue, name, formatter, parser, width="200px"}) {

    return(
        <div >
            <FormField label={`${name}`}>
                <InputNumber
                    value={value}
                    defaultValue={defaultValue}
                    min={minValue}
                    formatter={formatter}
                    parser={parser}
                    onChange={setValue}
                    style={{backgroundColor: "white", width: width}}
                />
            </FormField>
        </div>
    )
}