import { Table } from "@cloudscape-design/components";
import { useCollection } from '@cloudscape-design/collection-hooks';


import { createTableSortLabelFn } from "./table.ts";
import { TableEmptyState, TableNoMatchState } from "./common-components.tsx";
import { useLayoutEffect } from "react";
import getAllKeys from "../../utils/getAllKeys.js";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally.js";


export default function CustomFilteredTable({ elements, rawColumns, filters, emptyName="Table", children}) {

    const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

    const allKeys = getAllKeys(elements);
    const searchable_columns = allKeys;
    
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(elements, {
        filtering: {
            empty: <TableEmptyState resourceName={emptyName} />,
            noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
            filteringFunction: (item, filteringText) => {
                
                if (filters.some(filter => !filter.matchValue(item, filter.value))) {
                    consoleOnlyLocally("filteringFunction: false");
                    return false;
                }

                // filters.forEach(filter => {
                //     if (!filter.matchValue(item, filter.value)) {
                //         consoleOnlyLocally("filteringFunction: false")
                //         return false;
                //     }
                // });
        
                const filteringTextLowerCase = filteringText.toLowerCase();

                return searchable_columns.map(key => item[key]).some(
                    value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
                );
            },
        },
        pagination: { pageSize: 30 },
        sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[0] } },
        selection: {},
    });

    useLayoutEffect(() => {
        collectionProps.ref.current?.scrollToTop();
    }, [collectionProps.ref, filterProps.filteringText, ...filters.map(filter => filter.value)]);

    function clearFilter() {
        actions.setFiltering('');
        
        filters.forEach(filter => {
            filter.setValue(filter.defaultValue);
        });
    }

    return(
        <Table
            {...collectionProps} 
            resizableColumns={true}
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            filter={children}
        />
    )
}
