import { useState } from "react";

import Placement from "./placement/placement";
import Operations from "./operations/operations";
import Rente from "./rente/rente";
import CustomButton from "../../customs/components/CustomButton";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import CustomListButton from "../../customs/components/CustomListButton";


export default function LayoutPerf({ account, operations }) {

    let start_key = 0;

    const pages = []

    if(account.balanceInvestment > 0) {
        pages.push({
            key: start_key.toString(),
            label: "Placement",
            component: <Placement account={account} />
        })
        start_key++;
    }

    if(account.balancePension > 0) {
        pages.push({
            key: start_key.toString(),
            label: "Rente",
            component: <Rente account={account} operations={operations} />
        })
        start_key++;
    }

    // add element to pages
    pages.push({
        key: start_key.toString(),
        label: "Opérations",
        component: <Operations account={account} operations={operations} />
    })

    console.log(pages);


    let [selectedPage, setSelectedPage] = useState(pages["0"]);
    let [selectedKey, setSelectedKey] = useState("0");

    function handleChangePage(key) {
        setSelectedPage(pages[key]);
        setSelectedKey(key);
    }

    return(
        <>
            <CustomListButton 
                handleChangePage={handleChangePage} 
                selectedKey={selectedKey} 
                pages={pages}
            />

            {selectedPage.component}
            
        </>
    )
}