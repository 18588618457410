import { signOut } from "aws-amplify/auth";

export default function Maintenance() {

  return (
    <div id="error-page">
      <div className="div-fullscreen">
        <div className="div-container">
          <h1>Maintenance en cours!</h1>
          <p>Nous mettons à jour l'app pour la rendre plus performante !</p>
          <button onClick={() => signOut()}>Déconnexion</button>
        </div>
      </div> 
    </div>
  );
}
