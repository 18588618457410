import { Icon } from "@cloudscape-design/components";
import "./CustomRating.css";


export default function CustomRating({children, numberStars = 5}) {

    return(
        <div className="container-rating">
            {/* Add the number of numberStars of Icon star-filled */}
            {Array.from({length: numberStars}, (v, i) => i).map((item, index) => {
                return <Icon name="star-filled" key={index} />
            })}
            
            
            {/* <Icon name="star-filled" />
            <Icon name="star-filled" />
            <Icon name="star-filled" />
            <Icon name="star-filled" />
            <Icon name="star-filled" /> */}
            {children}
        </div>
    )
}