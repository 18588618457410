import consoleOnlyLocally from "../utils/consoleOnlyLocally";

import { getAccount } from "../services/calls/account";
import { getUsers } from "../services/calls/user";
import getUserTokenWithRetry from "../utils/testAuth";
import { getConfig } from "../services/calls/config";
import { redirect } from "react-router-dom";
import { getInfo21yield } from "../services/calls/info21yield";
import { getFintechs } from "../services/calls/fintech";
import { getOperations } from "../services/calls/operation";
import { getAssets } from "../services/calls/asset";
import { listFiles } from "../services/calls/files";


export async function dashboardLoader({ request }) {

    consoleOnlyLocally("homeLoader");
    await getUserTokenWithRetry() ; // Wait until auth is ready

    let dataConfig = await getConfig();
    let config = dataConfig.config;
    // consoleOnlyLocally(config);
  
    if(config.isMaintenance){
      consoleOnlyLocally("Maintenance mode is ON");
      return redirect("/maintenance");
    }

    let dataAccount = await getAccount();
    let account = dataAccount.account;
    let user = dataAccount.user;

    let dataUsers = await getUsers({accountID: account.accountID});
    let users = dataUsers.users;

    let dataOperations = await getOperations({accountID: account.accountID});
    let operations = dataOperations.operations;
    // sort the operations by onDate ascending
    operations = operations.sort((a, b) => new Date(a.onDate) - new Date(b.onDate));

    let cashouts = dataOperations.cashouts;
    // sort the cashouts by onDate ascending
    cashouts = cashouts.sort((a, b) => new Date(a.onDate) - new Date(b.onDate)); 
    
    let dataAssets = await getAssets({accountID: account.accountID});
    let assets = dataAssets.assets;

    let dataInfo21yield = await getInfo21yield();
    let info21yield = dataInfo21yield.info21yield;

    let dataFintechs = await getFintechs();
    let fintechs = dataFintechs.fintechs;

    
    let dataFiles = await listFiles();
    let files = dataFiles.files;

    return { user, account, users, operations, cashouts, assets, info21yield, fintechs, files };
  
}
  