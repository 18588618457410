

export function inputCurrencyFormatter(value){

    var result = Math.floor(parseFloat(value))
    return `€ ${result}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function inputCurrencyParser(value){

    return value?.replace(/€\s?|( *)/g, '');
}
