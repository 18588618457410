
export const RED_MAIN = "#cc1b0e";
export const RED_DARK = "#960d03";
export const RED_LIGHT = "#fa7a70";

export const GOLD_MAIN = "#CEBC80";
export const GOLD_LIGHT = "#fff9e5";
export const GOLD_DARK = "#b08838";

export const BLUE_DARK = "#2D3748";
export const BLUE_DARKER = "#1c232e";
export const BLUE_LIGHT = "#CFD5E1";

export const GREEN_MAIN = "#69E161";
export const GREEN_DARK = "#288321";

export const GRAY_LIGHT = "#c4c3c2";

export const YIELD_FOR_INVESTOR = 0.1;

// CATEGOIES and STATUS

export const STATUS_REQUESTED = "REQUESTED";
export const STATUS_PROCESSING = "PROCESSING";
export const STATUS_COMPLETED = "COMPLETED";

// DEFAULT VALUES
export const ACCOUNT_PART = "PARTICULIER";
export const ACCOUNT_PRO = "ENTREPRISE";
export const CATEGORY_RETAIL = "RETAIL";
export const CATEGORY_INSTITUTIONAL = "PROFESSIONEL";

