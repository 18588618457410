"use client"

import React, { useEffect, useState } from 'react';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer, 
  LabelList
} from 'recharts';

import { SpaceBetween } from '@cloudscape-design/components';
import { toFormatCurrency, toFormatDate } from '../../utils/toFormat';
import { DARK_GOLD, MAIN_GOLD } from '../../constants/colors';


export default function Forecasting({data}) {

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {

      const valueInvestment = payload[0].value;
      const dateTransaction = payload[0].payload.date;
      return (
        <div style={{
          padding:2,
          backgroundColor:"primary.main",
          borderRadius:"20px"        
        }}>
          <SpaceBetween size="s">
            <div className='subtitle-dark'>Le {toFormatDate(dateTransaction)}</div>
            <div className='subtitle-dark'>Votre placement vaudra {toFormatCurrency(valueInvestment)} €</div>
          </SpaceBetween>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 30,
            bottom: 10,
            right: 50,
            left: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={toFormatDate}/>
          {/* <YAxis tickFormatter={formattick}/> */}
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="value" stroke={DARK_GOLD} fill={MAIN_GOLD}>
            {/* <LabelList dataKey="value" position="top" formatter={formatlabel} fill={GOLD_MAIN} fontSize={20} fontWeight="bold" /> */}
            {/* <LabelList dataKey="value" position="top" fill={MAIN_GOLD} fontSize={20} fontWeight="bold" /> */}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
  